import { defineStore } from 'pinia'
import { computed, type Ref, ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import { NabooError } from '@/assets/classes/Error'
import { useToast } from 'primevue/usetoast'
import type { LearningCourseInputDto } from '@/assets/DTO/learning/learningCourse.dto'
import router from '@/router'
import { LearningEnums } from '@/assets/types/learning/enums'
import { LearningCourseResponseDto } from '@/assets/DTO/learning/learningCourse.response.dto'
import { useModulesStore } from '@/stores/learningModules'
import type { LearningModuleResponseDto } from '@/assets/DTO/learning/learningModule.response.dto'
import type { LearningQuizResponseDto } from '@/assets/DTO/learning/learningQuiz.response.dto'
import { LearningGrainResponseDto } from '@/assets/DTO/learning/learningGrain.response.dto'

export const useLearningCoursesStore = defineStore('learningCourses', () => {
  // UTILITY FUNCTIONS
  const toast = useToast()
  const { setModules } = useModulesStore()

  // STATE
  const learningCourses: Ref<LearningCourseResponseDto[]> = ref([])
  const learningCourse: Ref<LearningCourseResponseDto> = ref({} as LearningCourseResponseDto)
  const loading = ref(false)

  //COMPUTED -- Getters
  const learningCoursesCount = computed(() => learningCourses.value.length)

  // MUTATIONS
  function setCourses(courses: LearningCourseResponseDto[]) {
    learningCourses.value = courses
  }

  function addModuleToCourse(module: LearningModuleResponseDto) {
    learningCourse.value.learningModules.push(module)
  }

  function updateModuleInCourse(module: LearningModuleResponseDto) {
    const courseModule = learningCourse.value.learningModules.findIndex((m) => m.id === module.id)

    if (courseModule !== -1) {
      learningCourse.value.learningModules[courseModule] = module

      // filter the modules that are not active or draft
      learningCourse.value.learningModules = learningCourse.value.learningModules.filter(
        (module) =>
          module.status === LearningEnums.Status.DRAFT ||
          module.status === LearningEnums.Status.ACTIVE
      )
    }
  }

  function addCourse(course: LearningCourseResponseDto) {
    learningCourses.value.push(course)
  }

  function updateCourseWithApi(course: LearningCourseResponseDto) {
    learningCourse.value = course
    // filter the modules that are not active or draft
    learningCourse.value.learningModules = learningCourse.value.learningModules.filter(
      (module) =>
        module.status === LearningEnums.Status.DRAFT ||
        module.status === LearningEnums.Status.ACTIVE
    )
    const index = learningCourses.value.findIndex((c) => c.id === course.id)
    if (index !== -1) {
      if (course.status === LearningEnums.Status.ARCHIVED) {
        learningCourses.value.splice(index, 1)
        return
      }
      learningCourses.value[index] = course
    } else {
      addCourse(course)
    }
  }

  function updateQuizInCourse(quiz: LearningQuizResponseDto) {
    const taskIndex = learningCourse.value.domain.tasks.findIndex((m) => m.id === quiz.id)

    if (taskIndex !== -1) {
      learningCourse.value.domain.tasks[taskIndex].quiz = quiz
    }
  }

  function addGrainToModule(grain: LearningGrainResponseDto, moduleId: number) {
    const module = learningCourse.value.learningModules.find((module) => module.id === moduleId)

    if (module) {
      module.learningGrains.push(grain)
    }
  }

  // ACTIONS
  async function fetchAllCourses() {
    loading.value = true
    // Fetch courses from API
    try {
      const response = await nabooApi.getAllCourses()
      response.courses.forEach((course) => {
        course.learningModules = course.learningModules.filter(
          (module) =>
            module.status === LearningEnums.Status.DRAFT ||
            module.status === LearningEnums.Status.ACTIVE
        )
      })

      setCourses(response.courses)
    } catch (error) {
      let err: NabooError

      if (error instanceof NabooError) {
        err = error
      } else {
        err = new NabooError({
          message: 'Impossible de récupérer la liste des parcours',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: err.name,
        detail: err.message,
        life: 3000
      })

      learningCourses.value = []
    } finally {
      loading.value = false
    }
  }

  async function createLearningCourse(course: LearningCourseInputDto) {
    try {
      if (!course.isInputCorrect() && !learningCourseNames.value.exists(course.name))
        throw new NabooError({
          message: 'Les données du parcours sont incorrectes',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: new Error('Les données du parcours sont incorrectes')
        })
      const response = await nabooApi.createLearningCourse(course)

      addCourse(response)

      await router.push({ name: 'learning-courses-list' })

      toast.add({
        severity: 'success',
        summary: 'Parcours créé',
        detail: 'Le parcours a bien été créé',
        life: 3000
      })
    } catch (error) {
      let err: NabooError

      if (error instanceof NabooError) {
        err = error
      } else {
        err = new NabooError({
          message: 'Impossible de créer le parcours',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: err.name,
        detail: err.message,
        life: 3000
      })
    }
  }

  async function fetchCourse(id: number) {
    try {
      const response = await nabooApi.getLearningCourseById(id)

      updateCourseWithApi(response)
      setModules(response.learningModules)

      return Promise.resolve(response)
    } catch (error) {
      let err: NabooError

      if (error instanceof NabooError) {
        err = error
      } else {
        err = new NabooError({
          message: 'Impossible de récupérer le parcours',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: err.name,
        detail: err.message,
        life: 3000
      })

      return Promise.reject()
    }
  }

  async function updateLearningCourseStatus(id: number, status: LearningEnums.Status) {
    const course = getLearningCourseById(id)

    if (!course) {
      toast.add({
        severity: 'error',
        summary: 'Parcours introuvable',
        detail: 'Le parcours que vous souhaitez modifier est introuvable',
        life: 3000
      })

      return Promise.reject()
    }

    if (course.activeModules().length === 0 && status === LearningEnums.Status.ACTIVE) {
      toast.add({
        severity: 'error',
        summary: 'Parcours invalide',
        detail: 'Un parcours doit contenir au moins un module actif pour être activé',
        life: 3000
      })

      return Promise.reject()
    }

    try {
      const response = await nabooApi.updateLearningCourseStatus(id, status)

      updateCourseWithApi(response)

      toast.add({
        severity: 'success',
        summary: 'Statut mis à jour',
        detail: 'Le statut du parcours a bien été mis à jour',
        life: 3000
      })

      return Promise.resolve(response)
    } catch (error) {
      let err: NabooError

      if (error instanceof NabooError) {
        err = error
      } else {
        err = new NabooError({
          message: 'Impossible de mettre à jour le statut du parcours',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: err.name,
        detail: err.message,
        life: 3000
      })

      return Promise.reject()
    }
  }

  function getLearningCourseById(id: number) {
    return learningCourses.value.find((course) => course.id === id)
  }

  /**
   * Get all learning course names
   * @returns Promise<LearningCourseNamesResponseDto>
   */
  async function getAllLearningCourseNames() {
    try {
      const response = await nabooApi.getAllLearningCourseNames()
      return Promise.resolve(response)
    } catch (error) {
      let err: NabooError

      if (error instanceof NabooError) {
        err = error
      } else {
        err = new NabooError({
          message: 'Impossible de récupérer les noms des parcours',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: err.name,
        detail: err.message,
        life: 3000
      })

      return Promise.reject()
    }
  }

  return {
    learningCourses,
    learningCourse,
    loading,
    addModuleToCourse,
    addGrainToModule,
    updateModuleInCourse,
    fetchAllCourses,
    fetchCourse,
    getAllLearningCourseNames,
    getLearningCourseById,
    createLearningCourse,
    updateLearningCourseStatus,
    updateCourseWithApi,
    updateQuizInCourse,
    learningCoursesCount
  }
})
