import type { ISchoolYear } from '@/assets/types/SchoolYearTypes'

export interface ISchoolClassroom {
  id: number
  name: string
  degree: Degree.Degree
  schoolYear: ISchoolYear[]
}

export namespace Degree {
  export enum Degree {
    SECONDE = 'SECONDE',
    PREMIERE = 'PREMIERE',
    TERMINALE = 'TERMINALE'
  }

  export function label(degree: Degree) {
    switch (degree) {
      case Degree.SECONDE:
        return 'Seconde'
      case Degree.PREMIERE:
        return 'Première'
      case Degree.TERMINALE:
        return 'Terminale'
    }
  }
}
