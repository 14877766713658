<script lang="ts" setup>
import { useUserStore } from '@/stores/user'
import { default as PInputText } from 'primevue/inputtext'
import { default as PDropdown } from 'primevue/dropdown'
import { default as PMultiSelect } from 'primevue/multiselect'
import { default as PButton } from 'primevue/button'
import { computed, onMounted, ref } from 'vue'
import type {
  ICrewInput,
  ICrewStudentInfo,
  ICrewTeacherInfo,
  ICrewTutorInfo
} from '@/assets/types/CrewsTypes'
import type { IUser } from '@/assets/types/UserTypes'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useCrewStore } from '@/stores/crew'
import { useSchoolYearStore } from '@/stores/schoolYear'
import { useSchoolYearOptions } from '@/assets/utils/dataOptions'

// STORES
const { userListIsEmpty, getStudents, getTeachers, getTutors } = storeToRefs(useUserStore())
const { getAllUsers } = useUserStore()
const { fetchAllSchoolYears } = useSchoolYearStore()
const { getCurrentSchoolYear } = storeToRefs(useSchoolYearStore())
const { getCrewById, createCrew, updateCrew } = useCrewStore()
const { crewLoading } = storeToRefs(useCrewStore())
const route = useRoute()

// DATA
const crewInput = ref({} as ICrewInput)

// METHODS
async function handleSubmit() {
  if (route.name === 'edit-crew') {
    await updateCrew(crewInput.value)
  } else {
    await createCrew(crewInput.value)
  }
}

// COMPUTED
const studentOptions = computed(() => {
  return Object.values(
    getStudents.value
      .filter((student: IUser) => !!student.schoolId)
      .reduce((acc: any, student: IUser) => {
        let key = student.schoolId
        if (!acc[key]) {
          acc[key] = {
            label: student.schoolName,
            code: student.schoolId,
            items: []
          }
        }
        acc[key].items.push({ name: `${student.firstname} ${student.lastname}`, code: student.id })
        return acc
      }, [])
  )
})

const teacherOptions = computed(() => {
  return Object.values(
    getTeachers.value
      .filter((teacher: IUser) => !!teacher.schoolId)
      .reduce((acc: any, teacher: IUser) => {
        let key = teacher.schoolId
        if (!acc[key]) {
          acc[key] = {
            label: teacher.schoolName,
            code: teacher.schoolId,
            items: []
          }
        }
        acc[key].items.push({ name: `${teacher.firstname} ${teacher.lastname}`, code: teacher.id })
        return acc
      }, [])
  )
})

const tutorOptions = computed(() => {
  return getTutors.value.map((tutor: IUser) => {
    return {
      name: `${tutor.firstname} ${tutor.lastname}`,
      code: tutor.id
    }
  })
})

const canSubmit = computed(() => {
  return crewInput.value.name && crewInput.value.schoolYearId
})

onMounted(async () => {
  await fetchAllSchoolYears()
  if (userListIsEmpty.value) await getAllUsers()

  if (route.name === 'edit-crew') {
    const crew = await getCrewById(route.params.id)
    crewInput.value.id = crew.id
    crewInput.value.name = crew.name
    crewInput.value.schoolYearId = crew.schoolYear.id
    crewInput.value.teacherIds = crew.teachers.map((teacher: ICrewTeacherInfo) => teacher.id)
    crewInput.value.tutorIds = crew.tutors.map((tutor: ICrewTutorInfo) => tutor.id)
    crewInput.value.studentIds = crew.students.map((student: ICrewStudentInfo) => student.id)
  } else {
    crewInput.value.schoolYearId = getCurrentSchoolYear.value.id
  }
})
</script>

<template>
  <div class="p-4 h-full overflow-y-auto">
    <div class="mt-7 mx-7 grid">
      <div class="col-6">
        <h1 class="text-left">Créer un nouveau crew</h1>
        <small class="text-color"
          >Ajouter des élèves et un professeur pour créer un nouveau crew</small
        >
      </div>

      <div class="col-12 mt-5">
        <form @submit.prevent="handleSubmit()">
          <label class="block">Nom du crew *</label>
          <p-input-text
            class="block w-2 mt-2"
            v-model="crewInput.name"
            placeholder="Exemple : Crew-01"
            data-form-type="other"
          />

          <label class="block mt-4">Année scolaire *</label>
          <p-dropdown
            v-model="crewInput.schoolYearId"
            :options="useSchoolYearOptions"
            optionLabel="name"
            optionValue="code"
            placeholder="Sélectionner une année scolaire"
            class="mt-2"
          />

          <label class="block mt-4">Professeur·s Référent·s</label>
          <p-multi-select
            v-model="crewInput.teacherIds"
            :options="teacherOptions"
            optionGroupLabel="label"
            optionGroupChildren="items"
            optionLabel="name"
            optionValue="code"
            display="chip"
            filter
            placeholder="Sélectionner le·s professeur·s "
            class="w-8 mt-2"
          />

          <label class="block mt-4">Tuteur.s</label>
          <p-multi-select
            v-model="crewInput.tutorIds"
            :options="tutorOptions"
            option-label="name"
            optionValue="code"
            display="chip"
            filter
            placeholder="Sélectionner le·s tuteur·s "
            class="w-8 mt-2"
          />

          <label class="block mt-4">Elèves</label>
          <p-multi-select
            v-model="crewInput.studentIds"
            :options="studentOptions"
            optionGroupLabel="label"
            optionGroupChildren="items"
            optionLabel="name"
            optionValue="code"
            display="chip"
            filter
            placeholder="Sélectionner des élèves"
            class="w-8 mt-2"
          />

          <div class="mt-7 flex lg:justify-content-between">
            <p-button
              label="Annuler"
              severity="secondary"
              :loading="crewLoading"
              @click.prevent="$router.push({ name: 'crews-list' })"
            />
            <p-button
              label="Valider"
              severity="primary"
              type="submit"
              :loading="crewLoading"
              :disabled="!canSubmit"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
