import API from '@/services/API'

export const school = {
  async getAllSchools() {
    return API().get('admin/schools')
  },

  async getSchoolById(schoolId: number) {
    return API().get(`admin/schools/${schoolId}`)
  },

  async getSchoolClassrooms(schoolId: number) {
    return API().get(`admin/schools/${schoolId}/classrooms`)
  },

  async getSchoolClassroomById(schoolId: number, classroomId: number) {
    return API().get(`admin/schools/${schoolId}/classrooms/${classroomId}`)
  }
}
