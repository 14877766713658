<script setup lang="ts">
/*
 *
 * IMPORTS
 *
 * */

// PRIMEVUE
import { default as PInputGroup } from 'primevue/inputgroup'
import { default as PTextarea } from 'primevue/textarea'
import { default as PCheckbox } from 'primevue/checkbox'
import { default as PInputGroupAddon } from 'primevue/inputgroupaddon'
import { default as PButton } from 'primevue/button'

// Custom components and types
import type { IMCQAnswer } from '@/assets/types/learning/answers'

// VUE, VUE ROUTER, ETC...
import { type ModelRef, onMounted, type Ref, ref } from 'vue'
import { LearningEnums } from '@/assets/types/learning/enums'

const answers: ModelRef<IMCQAnswer[]> = defineModel('answers', { required: true })
const correctAnswers: Ref<number[]> = ref([])

onMounted(() => {
 answers.value.forEach((answer, i) => {
   if (answer.isCorrect) {
     correctAnswers.value.push(i)
   }
 })
})

function addQuestion() {
  answers.value.push({
    label: '',
    isCorrect: false,
    type: LearningEnums.QuestionType.MULTIPLE_CHOICE
  })
}

function updateAnswers() {
  answers.value.forEach((answer,i) => {
    answer.isCorrect = correctAnswers.value.includes(i)
  })
}

function deleteAnswer(index: number) {
  answers.value.splice(index, 1)
}
</script>

<template>

  <div class="flex flex-column gap-1">
    <p-input-group v-for="(answer, i) in answers" :key="`answer_${i}`">
      <p-input-group-addon>
        <p-checkbox
          :value="i"
          v-model="correctAnswers"
          name="answer"
          @update:model-value="() => updateAnswers()"
        />
      </p-input-group-addon>
      <p-textarea
        v-model="answer.label"
        style="min-height: 50px; height: 50px"
        placeholder="Texte de la réponse"
      />
      <p-button @click="deleteAnswer(i)" icon="pi pi-trash" text severity="secondary" />
    </p-input-group>

  </div>

  <p-button
    label="Ajouter une réponse"
    icon="pi pi-plus"
    class="mt-3"
    size="small"
    outlined
    @click="addQuestion"
  />
</template>
