import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import { useLocalStorage } from '@vueuse/core'
import type { RemovableRef } from '@vueuse/core/'
import type { IUser } from '@/assets/types/UserTypes'

export const useProfileStore = defineStore('Profile', () => {
  // STATE
  const me: RemovableRef<IUser> = ref(useLocalStorage('me', {} as IUser))

  // ACTIONS
  async function getMe() {
    try {
      const res = await nabooApi.getMe()
      me.value = res.data as IUser
    } catch (error: any) {
      me.value = {} as IUser
    }
  }

  // GETTERS (COMPUTED)
  const getFirstNameInitial = computed(() => {
    if (!me?.value?.firstname) return ''
    return me.value.firstname?.charAt(0)
  })

  const getFullName = computed(() => {
    if (!me?.value?.firstname || !me?.value?.lastname) return ''
    return `${me.value.firstname} ${me.value.lastname}`
  })

  return { getMe, me, getFirstNameInitial, getFullName }
})
