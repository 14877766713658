import nabooApi from '@/services/nabooApi'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { ICrew, ICrewInput, ICrewStudentInfo } from '@/assets/types/CrewsTypes'
import { useToast } from 'primevue/usetoast'
import router from '@/router'

export const useCrewStore = defineStore('Crew', () => {
  // UTILITY FUNCTIONS
  const toast = useToast()

  // STATE
  const crewLoading = ref(false)
  const crewList = ref([] as ICrew[])

  // ACTIONS
  async function getAllCrews() {
    crewLoading.value = true

    try {
      const res = await nabooApi.fetchAllCrews()
      crewList.value = res.data
    } catch (error: any) {
      crewList.value = [] as ICrew[]
    } finally {
      crewLoading.value = false
    }
  }

  async function getCrewById(crewId: number) {
    crewLoading.value = true

    try {
      const res = await nabooApi.fetchCrewById(crewId)
      const crew = res.data
      if (crew?.id)
        crewList.value.splice(
          crewList.value.findIndex((c: ICrew) => c.id === crew.id),
          1,
          crew
        )
      return crew
    } catch (error: any) {
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: error.detail,
        life: 3000
      })
    } finally {
      crewLoading.value = false
    }
  }

  async function createCrew(crewInput: ICrewInput) {
    crewLoading.value = true

    try {
      const res = await nabooApi.createCrew(crewInput)
      crewList.value.push(res.data)
      toast.add({
        severity: 'success',
        summary: 'Crew créé',
        detail: `Le crew ${crewInput.name} a été créé avec succès`,
        life: 3000
      })
      await router.push({ name: 'crews-list' })
    } catch (error: any) {
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: error.detail,
        life: 3000
      })
    } finally {
      crewLoading.value = false
    }
  }

  async function updateCrew(crewInput: ICrewInput) {
    crewLoading.value = true

    try {
      const res = await nabooApi.updateCrew(crewInput)
      const crew = res.data
      if (crew?.id)
        crewList.value.splice(
          crewList.value.findIndex((c: ICrew) => c.id === crew.id),
          1,
          crew
        )
      toast.add({
        severity: 'success',
        summary: 'Crew mis à jour',
        detail: `Le crew ${crewInput.name} a été mis à jour avec succès`,
        life: 3000
      })
      await router.push({ name: 'crews-list' })
    } catch (error: any) {
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: error.detail,
        life: 3000
      })
    } finally {
      crewLoading.value = false
    }
  }

  async function deleteCrew(crew: ICrew) {
    crewLoading.value = true
    try {
      await nabooApi.deleteCrew(crew.id)
      crewList.value.splice(
        crewList.value.findIndex((c: ICrew) => c.id === crew.id),
        1
      )
      toast.add({
        severity: 'success',
        summary: 'Crew supprimé',
        detail: `Le crew ${crew.name} a été supprimé avec succès`,
        life: 3000
      })
    } catch (error: any) {
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: error.detail,
        life: 3000
      })
    } finally {
      crewLoading.value = false
    }
  }

  async function deleteStudentFromCrew(crew: ICrew, student: ICrewStudentInfo) {
    crewLoading.value = true

    try {
      const c = crewList.value.find((c: ICrew) => c.id === crew.id)
      c.students.splice(
        c.students.findIndex((s: any) => s.id === student.id),
        1
      )

      // Convert to input
      const crewInput: ICrewInput = {
        id: c.id,
        name: c.name,
        schoolYearId: c.schoolYear.id,
        teacherIds: c.teachers.map((t: any) => t.id),
        tutorIds: c.tutors.map((t: any) => t.id),
        studentIds: c.students.map((s: any) => s.id)
      }
      const res = await nabooApi.updateCrew(crewInput)
      const uCrew = res.data as ICrew
      if (uCrew?.id)
        crewList.value.splice(
          crewList.value.findIndex((c: ICrew) => c.id === crew.id),
          1,
          uCrew
        )
      toast.add({
        severity: 'success',
        summary: 'Élève supprimé du crew',
        detail: `L'élève ${student.firstname} ${student.lastname} a bien été supprimé du crew ${uCrew.name} avec succès`,
        life: 3000
      })
    } catch (error: any) {
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: error.detail,
        life: 3000
      })
    } finally {
      crewLoading.value = false
    }
  }

  return {
    crewLoading,
    crewList,
    getAllCrews,
    getCrewById,
    createCrew,
    updateCrew,
    deleteCrew,
    deleteStudentFromCrew
  }
})
