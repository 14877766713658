import VUpdateModule from '@/views/modules/VUpdateModule.vue'
import VUsers from '@/views/users/VUsers.vue'
import VAddUser from '@/views/users/VAddUser.vue'
import VCrewsList from '@/views/crews/VCrewsList.vue'
import VCrews from '@/views/crews/VCrews.vue'
import VUpdateCrew from '@/views/crews/VUpdateCrew.vue'
import VHome from '@/views/home/VHome.vue'
import VEditQuiz from '@/views/quiz/VEditQuiz.vue'
import { createAuthGuard } from '@auth0/auth0-vue'
import { Role } from '@/assets/types/UserTypes'

import {
  VLearningCoursesList,
  VLearningCourses,
  VAddLearningCourse,
  VUpdateLearningCourse,
  VLearningCourse
} from '@/views/learningCourses'
import type { RouteLocationNormalized } from 'vue-router'

/**
 * Auth0 guard
 */
const authGuard = createAuthGuard()

export const routes = [
  {
    path: '/',
    name: 'home',
    component: VHome,
    beforeEnter: [authGuard],
    meta: {
      group: 'home',
      withSidebar: true,
      roles: ['ADMIN']
    }
  },
  {
    path: '/parcours',
    name: 'learning-courses',
    beforeEnter: [authGuard],
    component: VLearningCourses,
    children: [
      {
        path: '',
        name: 'learning-courses-list',
        component: VLearningCoursesList,
        beforeEnter: [authGuard],
        meta: {
          group: 'learning-courses',
          roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
        }
      },
      {
        path: 'ajouter',
        name: 'learning-courses-add',
        component: VAddLearningCourse,
        beforeEnter: [authGuard],
        meta: {
          group: 'learning-courses',
          roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
        }
      },
      {
        path: ':id(\\d+)',
        component: VLearningCourse,
        beforeEnter: [authGuard],
        props: (route: RouteLocationNormalized) => ({ id: Number(route.params.id) }),
        children: [
          {
            path: 'modifier',
            name: 'learning-course-update',
            component: VUpdateLearningCourse,
            beforeEnter: [authGuard],
            meta: {
              group: 'learning-courses',
              roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
            }
          },
          {
            path: 'quiz/:quizId(\\d+)',
            name: 'learning-course-update-quiz',
            component: VEditQuiz,
            beforeEnter: [authGuard],
            props: (route: RouteLocationNormalized) => ({
              id: Number(route.params.id),
              quizId: Number(route.params.quizId),
              taskId: Number(route.query.taskId)
            }),
            meta: {
              group: 'learning-courses',
              roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
            }
          },
          {
            path: 'modules',
            children: [
              {
                path: 'ajouter',
                name: 'learning-course-add-module',
                component: VUpdateModule,
                beforeEnter: [authGuard],
                meta: {
                  group: 'learning-courses',
                  roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
                }
              },
              {
                path: ':moduleId(\\d+)',
                children: [
                  {
                    path: '',
                    name: 'learning-course-update-module',
                    component: VUpdateModule,
                    beforeEnter: [authGuard],
                    props: (route: RouteLocationNormalized) => ({
                      id: Number(route.params.id),
                      moduleId: Number(route.params.moduleId)
                    }),
                    meta: {
                      group: 'learning-courses',
                      roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
                    }
                  },
                  {
                    path: 'grain/:grainId(\\d+)/quiz/:quizId(\\d+)',
                    name: 'learning-grain-update-quiz',
                    component: VEditQuiz,
                    beforeEnter: [authGuard],
                    props: (route: RouteLocationNormalized) => ({
                      id: Number(route.params.id),
                      moduleId: Number(route.params.moduleId),
                      grainId: Number(route.params.grainId),
                      quizId: Number(route.params.quizId)
                    }),
                    meta: {
                      group: 'learning-courses',
                      roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/utilisateurs',
    name: 'users-list',
    component: VUsers,
    beforeEnter: [authGuard],
    meta: {
      group: 'users',
      withSidebar: true,
      roles: ['ADMIN']
    }
  },
  {
    path: '/ajouter',
    name: 'create-user',
    component: VAddUser,
    beforeEnter: [authGuard],
    meta: {
      group: 'users',
      withSidebar: true,
      roles: ['ADMIN']
    }
  },
  {
    path: '/modifier/:userIdentifier',
    name: 'edit-user',
    component: VAddUser,
    beforeEnter: [authGuard],
    meta: {
      group: 'users',
      withSidebar: true,
      roles: ['ADMIN']
    }
  },
  {
    path: '/crews',
    component: VCrews,
    beforeEnter: [authGuard],
    children: [
      {
        path: '',
        name: 'crews-list',
        component: VCrewsList,
        beforeEnter: [authGuard],
        children: [],
        meta: {
          group: 'crews',
          withSidebar: true,
          roles: ['ADMIN']
        }
      },
      {
        path: 'ajouter',
        name: 'add-crew',
        component: VUpdateCrew,
        beforeEnter: [authGuard],
        children: [],
        meta: {
          group: 'crews',
          withSidebar: true,
          roles: ['ADMIN']
        }
      },
      {
        path: 'modifier/:id',
        name: 'edit-crew',
        component: VUpdateCrew,
        beforeEnter: [authGuard],
        children: [],
        meta: {
          group: 'crews',
          withSidebar: true,
          roles: ['ADMIN']
        }
      }
    ],
    meta: {
      group: 'crews',
      withSidebar: true,
      roles: ['ADMIN']
    }
  }
]
