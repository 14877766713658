<script lang="ts" setup>
import { onMounted } from 'vue'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

onMounted(() => {
  userStore.getAllUsers()
})
</script>

<template><router-view></router-view></template>
