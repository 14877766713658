<script setup lang="ts">
/* ---------------------
--------IMPORTS---------
----------------------*/
//PrimeVue
import { default as PButton } from 'primevue/button'
import { default as PImage } from 'primevue/image'
import { default as PAvatar } from 'primevue/avatar'
import { default as PMenu } from 'primevue/menu'
import type { MenuItem } from 'primevue/menuitem'

//Vue & Vue Router
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import router from '@/router'
import { useRoute } from 'vue-router'

// Custom
import largeLogoUrl from '@/assets/images/logo-naboo-bleu.svg'
import smallLogoUrl from '@/assets/images/logo-naboo-bleu-small.svg'
import { useProfileStore } from '@/stores/profile'
import { useAuthStore } from '@/stores/auth'

const route = useRoute()
const profile = useProfileStore()
const { initLogout } = useAuthStore()

// DATA, PROPS & REFS
const isOpened = ref(true)
const isFullyOpened = ref(true)
const links = ref([
  {
    name: 'Home',
    link: '/',
    icon: 'pi-home',
    group: 'home'
  },
  {
    name: 'Utilisateurs',
    link: 'users-list',
    icon: 'pi-user',
    group: 'users'
  },
  {
    name: 'Crews',
    link: 'crews-list',
    icon: 'pi-users',
    group: 'crews'
  },
  {
    name: 'Parcours',
    link: 'learning-courses-list',
    icon: 'pi-th-large',
    group: 'learning-courses'
  }
])

const menuWrapper: Ref<HTMLElement | null> = ref(null)
const menu = ref()
const items: Ref<MenuItem[]> = ref([
  {
    label: 'Se déconnecter',
    command: () => initLogout()
  }
])

// METHODS
async function processClick(link: string) {
  link === '/' ? await router.replace({ path: '/' }) : await router.push({ name: link })
}

function toggleMenu() {
  if (!isOpened.value)
    setTimeout(() => {
      isFullyOpened.value = true
    }, 200)
  else isFullyOpened.value = false
  isOpened.value = !isOpened.value
}

const toggle = (event: Event) => {
  menu.value.toggle(event)
}

// COMPUTED PROPERTIES
const logoUrl = computed(() => {
  return isFullyOpened.value ? largeLogoUrl : smallLogoUrl
})

const currentGroup = computed(() => {
  return route?.meta?.group ?? 'home'
})
</script>

<template>
  <div
    class="_m-Lateral-Menu relative"
    :class="isOpened ? 'opened' : ['closed', 'flex', 'flex-column', 'align-items-center']"
    ref="menuWrapper"
  >
    <!--Logo-->
    <p-image v-if="isFullyOpened" :src="logoUrl" image-class="w-full" />
    <p-image v-else :src="logoUrl" class="block max-w-3rem" />

    <!--Profile-->
    <p-button
      @click="toggle"
      aria-haspopup="true"
      aria-controls="overlay_menu"
      text
      class="p-1 mt-3 w-full justify-content-between"
    >
      <p-avatar :label="profile.getFirstNameInitial?.toLocaleUpperCase()" shape="circle" />
      <span v-if="isFullyOpened" class="pl-1">{{ profile.getFullName }}</span>
      <i class="pi pi-chevron-down ml-2"></i>
    </p-button>
    <p-menu ref="menu" id="overlay_menu" :model="items" :popup="true" />

    <div
      class="_m-Lateral-Menu-Nav mt-3 flex flex-column"
      :class="{ 'align-items-center': !isFullyOpened }"
    >
      <div
        v-for="(item, i) in links"
        :key="`link_${i}`"
        class="_m-Lateral-Menu-Nav__Item flex align-items-center my-2"
        :class="[currentGroup === item.group ? 'selected' : '']"
        @click="processClick(item.link)"
      >
        <i class="pi flex justify-content-center align-items-center" :class="[item.icon]"></i>
        <h5 v-if="isFullyOpened" class="ml-3 font-bold">{{ item.name }}</h5>
      </div>
    </div>

    <p-button
      @click="toggleMenu()"
      rounded
      icon="pi pi-arrow-right"
      text
      class="absolute right-0 bottom-0 _m-Lateral-Menu-Toggle"
    />
  </div>
</template>

<style scoped lang="scss">
._m-Lateral-Menu {
  height: 100vh;
  border-right: 1px solid var(--gray-400);
  transition: all ease 0.3s;
  padding: 24px 20px;
  background-color: #fff;

  // Variants
  &.opened {
    width: 220px;

    .p-button._m-Lateral-Menu-Toggle {
      position: relative;
      transform: rotateZ(180deg);
    }
  }

  &.closed {
    width: 105px;
  }

  ._m-Lateral-Menu-Nav {
    ._m-Lateral-Menu-Nav__Item {
      border-radius: 6px;
      transition: all ease 0.3s;

      &:hover {
        cursor: pointer;

        i {
          color: var(--blue-400);
          background-color: var(--gray-100);
        }
      }

      i {
        width: 40px;
        height: 38px;
        color: var(--gray-500);
        background-color: transparent;
        border-radius: 6px;
        transition: all ease 0.3s;
      }

      //state of component
      &.selected {
        i {
          color: var(--blue-400);
          background-color: var(--blue-100);
        }
      }
    }
  }
}
</style>
