import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import type { ISchoolYear } from '@/assets/types/SchoolYearTypes'

export const useSchoolYearStore = defineStore('SchoolYear', () => {
  const schoolYearList = ref<ISchoolYear[]>([])

  // ACTIONS
  async function fetchAllSchoolYears() {
    try {
      const res = await nabooApi.getAllSchoolYears()
      schoolYearList.value = res.data.map(parsePayloadToSchoolYear)
    } catch (error: any) {
      schoolYearList.value = [] as ISchoolYear[]
    }
  }

  async function refreshSchoolYearById(id: number) {
    const idx = schoolYearList.value.findIndex((schoolYear) => schoolYear.id === id)
    if (idx === -1) return
    const res = await nabooApi.getSchoolYearById(id)
    schoolYearList.value.splice(idx, 1, parsePayloadToSchoolYear(res.data))
  }

  // GETTERS (COMPUTED)
  const getCurrentSchoolYear = computed(() => {
    return schoolYearList.value.find((schoolYear) => schoolYear.isCurrent)
  })

  // FUNCTIONS
  function parsePayloadToSchoolYear(payload: any) {
    return {
      id: payload.id,
      startDate: new Date(payload.startDate),
      endDate: new Date(payload.endDate),
      label: payload.label,
      isCurrent: payload.isCurrent
    } as ISchoolYear
  }

  return { schoolYearList, fetchAllSchoolYears, refreshSchoolYearById, getCurrentSchoolYear }
})
