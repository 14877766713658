<script setup lang="ts">
import { default as PDropdown } from 'primevue/dropdown'
import { default as PInputText } from 'primevue/inputtext'
import { default as PDataTable } from 'primevue/datatable'
import { default as PColumn } from 'primevue/column'
import { computed, onMounted, ref } from 'vue'
import { default as PButton } from 'primevue/button'
import { storeToRefs } from 'pinia'
import { useCrewStore } from '@/stores/crew'
import type {
  ICrew,
  ICrewStudentInfo,
  ICrewTeacherInfo,
  ICrewTutorInfo
} from '../../assets/types/CrewsTypes'
import { useSchoolYearStore } from '@/stores/schoolYear'
import { useSchoolYearOptions } from '@/assets/utils/dataOptions'

const { getAllCrews, deleteCrew, deleteStudentFromCrew } = useCrewStore()
const { crewLoading, crewList } = storeToRefs(useCrewStore())
const { fetchAllSchoolYears } = useSchoolYearStore()
const { getCurrentSchoolYear } = storeToRefs(useSchoolYearStore())

const expandedRows = ref([])
const schoolYearFilter = ref('')
const searchField = ref('')

async function handleDeleteCrew(crew: ICrew) {
  const res = confirm(`Voulez-vous vraiment supprimer le crew ${crew.name} ?`)
  if (res) await deleteCrew(crew)
}

async function removeStudentFromCrew(crew: ICrew, student: ICrewStudentInfo) {
  const res = confirm(
    `Voulez-vous vraiment supprimer ${student.firstname} ${student.lastname} du crew ${crew.name} ?`
  )
  if (res) {
    await deleteStudentFromCrew(crew, student)
  }
}

const filteredCrewList = computed(() => {
  if (!searchField.value && !schoolYearFilter.value)
    return Object.assign([], crewList.value).sort((a: ICrew, b: ICrew) => a.id - b.id)
  return crewList.value
    .filter((crew: ICrew) => {
      return (
        crew.schoolYear.id.toString() === schoolYearFilter.value.toString() &&
        (crew.name.toLowerCase().includes(searchField.value.toLowerCase()) ||
          crew.teachers
            .map((teacher: ICrewTeacherInfo) =>
              `${teacher.firstname} ${teacher.lastname} ${teacher.school.name}`.toLowerCase()
            )
            .join(', ')
            .includes(searchField.value.toLowerCase()) ||
          crew.tutors
            .map((tutor: ICrewTutorInfo) => `${tutor.firstname} ${tutor.lastname}`.toLowerCase())
            .join(', ')
            .includes(searchField.value.toLowerCase()) ||
          crew.students
            .map((student: ICrewStudentInfo) =>
              `${student.firstname} ${student.lastname} ${student.school.name}`.toLowerCase()
            )
            .join(', ')
            .includes(searchField.value.toLowerCase()))
      )
    })
    .sort((a: ICrew, b: ICrew) => a.id - b.id)
})

onMounted(async () => {
  await fetchAllSchoolYears()
  await getAllCrews()
  schoolYearFilter.value = getCurrentSchoolYear.value.id
})
</script>
<template>
  <div class="p-4 grid h-full overflow-y-auto">
    <div class="mt-2 mx-2 flex-column justify-content-start">
      <div class="col-12 flex">
        <div class="col-5">
          <h1 class="text-left">Crews</h1>
          <small class="text-color">Retrouvez ici la liste de tous les crews.</small>
        </div>

        <div class="col-2 flex flex-column gap-2">
          <p-dropdown
            v-model="schoolYearFilter"
            :options="useSchoolYearOptions"
            optionLabel="name"
            optionValue="code"
          >
          </p-dropdown>
        </div>

        <div class="col-3 flex flex-column gap-2">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <p-input-text v-model="searchField" class="w-full" aria-describedby="search-help" />
          </span>
          <small id="search-help">Rechercher par crews, noms, prénoms, écoles</small>
        </div>

        <div class="col-2 align-content-end">
          <p-button
            label="Créer un crew"
            icon="pi pi-plus-circle"
            :loading="crewLoading"
            @click="$router.push({ name: 'add-crew' })"
          />
        </div>
      </div>
      <p-data-table
        :loading="crewLoading"
        :value="filteredCrewList"
        class="col-12 flex justify-content-start mt-2 mb-4"
        v-model:expandedRows="expandedRows"
      >
        <p-column expander style="width: 2rem" />
        <p-column field="name" header="Crew" sortable="" style="width: 10%"></p-column>
        <p-column field="teachers" style="width: 25%">
          <template #body="slotProps">
            <strong>Prof référent : </strong>
            {{
              slotProps.data.teachers
                .map(
                  (teacher: ICrewTeacherInfo) => `${teacher.firstname}
              ${teacher.lastname}`
                )
                .join(', ')
            }}
          </template>
        </p-column>
        <p-column field="tutors">
          <template #body="slotProps">
            <strong>Tuteurs : </strong>
            {{
              slotProps.data.tutors
                .map((tutor: ICrewTutorInfo) => `${tutor.firstname} ${tutor.lastname}`)
                .join(', ')
            }}
          </template>
        </p-column>
        <p-column header="Points" style="width: 8rem">
          <template #body="slotProps">
            <strong>{{
              slotProps.data.students.reduce(
                (acc: number, student: ICrewStudentInfo) => (acc += student.points),
                0
              )
            }}</strong>
          </template>
        </p-column>
        <p-column header="Actions" style="width: 9rem">
          <template #body="slotProps">
            <p-button
              icon="pi pi-pencil"
              severity="secondary"
              class="mr-4"
              text
              style="padding: 0; width: auto"
              @click.prevent="
                $router.push({ name: 'edit-crew', params: { id: slotProps.data.id } })
              "
            />
            <p-button
              icon="pi pi-trash"
              text
              severity="secondary"
              style="padding: 0; width: auto"
              @click.prevent="handleDeleteCrew(slotProps.data)"
            />
          </template>
        </p-column>

        <template #expansion="slotProps">
          <p-data-table
            class="font-italic"
            :value="slotProps.data.students"
            :pt="{ thead: { style: { display: 'none' } } }"
          >
            <p-column style="width: 5rem" />
            <p-column field="firstname" style="width: 10%"></p-column>
            <p-column field="lastname" style="width: 10%"></p-column>
            <p-column field="school.name"></p-column>
            <p-column field="points" style="width: 8rem"></p-column>
            <p-column style="width: 9rem">
              <template #body="rowData">
                <p-button
                  icon="pi pi-minus-circle"
                  severity="secondary"
                  class="mr-4"
                  text
                  style="padding: 0 2px 0 0; width: auto"
                  @click.prevent="removeStudentFromCrew(slotProps.data, rowData.data)"
                />
              </template>
            </p-column>
          </p-data-table>
        </template>
      </p-data-table>
    </div>
  </div>
</template>

<style lang="scss">
.p-datatable-row-expansion {
  > td {
    padding: 0 !important;
  }
}
</style>
