import API from '@/services/API'

export const schoolYear = {
  async getAllSchoolYears() {
    return API().get('admin/school-years')
  },

  async getSchoolYearById(schoolYearId: number) {
    return API().get(`admin/school-years/${schoolYearId}`)
  },

  async getSchoolYearCurrent() {
    return API().get('admin/school-years/current')
  }
}
