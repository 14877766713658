import { defineStore } from 'pinia'
import type { ISchool } from '@/assets/types/SchoolTypes'
import { computed, ref } from 'vue'
import nabooApi from '@/services/nabooApi'

export const useSchoolStore = defineStore('School', () => {
  const schoolList = ref<ISchool[]>([])

  // ACTIONS
  async function fetchAllSchools() {
    try {
      const res = await nabooApi.getAllSchools()
      schoolList.value = res.data
    } catch (error: any) {
      schoolList.value = [] as ISchool[]
    }
  }

  // GETTERS (COMPUTED)
  const getSchoolById = computed(() => {
    return (id: number) => {
      return schoolList.value.find((school) => school.id === id)
    }
  })

  return { schoolList, fetchAllSchools, getSchoolById }
})
