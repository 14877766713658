import { profile } from '@/services/API/profile'
import { learningModules } from '@/services/API/learning/learningModules'
import { users } from '@/services/API/user'
import { schoolYear } from '@/services/API/schoolYear'
import { school } from '@/services/API/school'
import { crews } from '@/services/API/crew'
import { referential } from '@/services/API/referential'
import { learningCourses } from './API/learning/learningCourses'
import { learningGrains } from '@/services/API/learning/learningGrains'
import { learningQuiz } from '@/services/API/learning/learningQuiz'

const modules = {
  ...profile,
  ...school,
  ...schoolYear,
  ...learningModules,
  ...users,
  ...crews,
  ...referential,
  ...learningCourses,
  ...learningGrains,
  ...learningQuiz
}

export default modules
